import React from "react";
import clsx from "clsx";

export const Heading1: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > & {
    size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    ["data-testid"]?: string;
  }
> = ({ size = "h1", className, children, ...props }) => {
  const sizeClassName = clsx({
    "text-5xl": size === "h1",
    "text-4xl": size === "h2",
    "text-3xl": size === "h3",
    "text-2xl": size === "h4",
    "text-xl": size === "h5",
    "text-lg": size === "h6",
  });

  return (
    <h1 className={clsx("text-navy-800", sizeClassName, className)} {...props}>
      {children}
    </h1>
  );
};
