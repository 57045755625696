import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

const labelClassName =
  "absolute left-3 top-[-1ex] z-[1] bg-white px-1 text-xs leading-4 text-gray-600";
const inputClassName =
  "ring-offset placeholder:text-navy-200 text-navy-800 border-navy-100 focus:border-navy-500 focus-visible:ring-none flex max-h-16 w-full border border-solid bg-white px-4 py-5 text-lg leading-6 transition-colors delay-0 ease-in-out focus:transition-colors focus-visible:outline-none";
const errorClassName =
  "mt-2 inline-block place-self-start text-sm leading-6 text-red-600";

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, error, ...props }, ref) => {
    if (!props.id && !!label) {
      console.warn(
        `Input with label ${label} is missing an id. This is required for accessibility.`
      );
    }

    return (
      <div className="relative w-full">
        {label && (
          <label className={labelClassName} htmlFor={props.id}>
            {label}
          </label>
        )}
        <input
          type={type}
          className={cn(
            inputClassName,
            "disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
        {error && <span className={errorClassName}>{error}</span>}
      </div>
    );
  }
);
Input.displayName = "Input";

const FalseInput = React.forwardRef<HTMLDivElement, InputProps>(
  ({ className, label, error, children, ...props }, ref) => {
    return (
      <div className="relative w-full">
        {label && <div className={labelClassName}>{label}</div>}
        <div className={cn(inputClassName, className)} ref={ref} {...props}>
          {children}
        </div>
        {error && <span className={errorClassName}>{error}</span>}
      </div>
    );
  }
);
FalseInput.displayName = "FalseInput";

export { Input, FalseInput };
