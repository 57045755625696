import React from "react";
import clsx from "clsx";

export const Heading4: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
> = ({ className, children, ...props }) => {
  return (
    <h4 className={clsx("text-navy-800 text-sm", className)} {...props}>
      {children}
    </h4>
  );
};
