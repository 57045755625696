"use client";

import React, { useRef, ElementRef, useState, useTransition } from "react";
import Image from "next/image";
import { Input } from "@/components/ui/input";
import { Turnstile } from "@marsidev/react-turnstile";

import mlhLogo from "~/img/logo/mlh.svg";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { env } from "@/env";
import { startSignIn } from "@/app/(auth)/signin/action";
import { Heading1 } from "@/components/Typography";
import AuthLayoutHeader from "@/components/AuthLayout/Header";
import { captureException } from "@sentry/nextjs";
import { AuthLayoutContainerForm } from "@/components/AuthLayout/Container";
import { useRouter } from "next/navigation";

type SignInPageProps = {
  heading?: string;
  submitButtonLabel?: string;
  redirectCta?: string;
  redirectButtonLabel?: string;
  redirectButtonHref?: string;
  turnstileAction?: string;
  skipTurnstile?: boolean;
};

const SignInPage: React.FC<SignInPageProps> = ({
  heading = "Welcome back",
  submitButtonLabel = "Sign In",
  redirectCta = "Or don't have an account?",
  redirectButtonLabel = "Sign Up",
  redirectButtonHref = "/signup",
  turnstileAction = "signin",
  skipTurnstile = false,
}) => {
  const formRef = useRef<ElementRef<"form">>(null);
  const turnstileRef = useRef<ElementRef<typeof Turnstile>>(null);
  const [error, setError] = useState<{
    general?: string;
    email?: string;
  }>({});
  const router = useRouter();
  const [isPending, startTransition] = useTransition();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    processSignAttempt();
  };

  const processSignAttempt = () =>
    startTransition(async () => {
      const formData = new FormData(formRef.current || undefined);
      try {
        if (!skipTurnstile) {
          formData.set(
            "cf-turnstile-response",
            (await turnstileRef.current?.getResponsePromise()) || ""
          );
        }
      } catch (err) {
        captureException(err);
      }

      try {
        const result = await startSignIn(formData, turnstileAction);
        if (result?.error) {
          setError(() => ({ [result.field || "general"]: result.error }));
        } else {
          setError(() => ({}));
        }
      } catch (err) {
        captureException(err);
        setError(() => ({ general: (err as { message?: string })?.message }));
      }

      turnstileRef.current?.reset();
    });

  return (
    <>
      <AuthLayoutHeader>
        <Image src={mlhLogo} alt="MLH Logo" width={95} height={40} />
        <Heading1 className="text-center" data-testid="heading">
          {heading}
        </Heading1>
      </AuthLayoutHeader>
      <AuthLayoutContainerForm space onSubmit={handleSubmit} ref={formRef}>
        <Input
          type="text"
          placeholder="name@email.com"
          name="email"
          id="signin-email"
          label="Email"
          error={error.email}
          data-testid="signin-email-input"
          data-sentry-mask
        />
        {!skipTurnstile && (
          <Turnstile
            className="!mt-0 self-start"
            siteKey={env.NEXT_PUBLIC_TURNSTILE_SITE_KEY}
            options={{
              action: turnstileAction,
              theme: "light",
              size: "invisible",
              appearance: "always",
            }}
            ref={turnstileRef}
          />
        )}
        <Button
          type="submit"
          variant="primary"
          className="w-full justify-between"
          disabled={isPending}
          data-testid="signin-submit"
        >
          {submitButtonLabel}
          <ArrowRight />
        </Button>
        {error.general && (
          <span
            className="place-self-start text-sm leading-6 text-red-600"
            data-testid="general-error"
          >
            {error.general}
          </span>
        )}
        <hr className="w-full px-6" />
        <span
          className="place-self-start text-sm leading-6 text-gray-700"
          data-testid="redirect-cta"
        >
          {redirectCta}
        </span>
        <Button
          type="button"
          variant="invert"
          className="w-full justify-between"
          onClick={() => router.push(redirectButtonHref)}
          data-testid="redirect-button"
        >
          {redirectButtonLabel}
          <ArrowRight />
        </Button>
      </AuthLayoutContainerForm>
    </>
  );
};

export default SignInPage;
