import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap transition-colors hover:transition-colors ease-in-out delay-150 disabled:pointer-events-none disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-navy-500 focus-visible:ring-none border",
  {
    variants: {
      variant: {
        primary:
          "bg-navy-800 text-white hover:bg-navy-600 border-navy-500 hover:border-navy-800",
        secondary:
          "bg-blue-500 text-white hover:bg-blue-400 border-navy-500 hover:border-navy-800",
        danger:
          "bg-red-500 text-white hover:bg-red-600 border-red-500 hover:border-red-800",
        dangerInvert: "bg-white text-red-500 hover:bg-red-50 border-red-100",
        linkDanger:
          "border-none bg-transparent text-red-500 hover:text-red-600 hover:underline",
        invert: "bg-navy-50 hover:bg-white text-navy-800 border-navy-100",
        white: "bg-white hover:bg-gray-100 text-navy-800 border-navy-100",
        link: "border-none bg-transparent text-navy-800 hover:text-navy-900 underline hover:no-underline font-base !p-0 !text-base !font-normal !h-[inherit]",
        linkPrimary:
          "border-none bg-transparent text-blue-500 hover:text-blue-600 no-underline hover:underline font-base !p-0 !text-base !font-normal !h-[inherit]",
        ghost: "bg-transparent text-navy-800 hover:bg-navy-50 border-navy-100",
        linkSecondary:
          "border-none bg-transparent text-gray-400 hover:text-gray-900 hover:underline",
        outline:
          "ring-offset placeholder:text-navy-200 text-navy-800 border-navy-100 focus:border-navy-500 focus-visible:ring-none flex max-h-16 border border-solid bg-white px-4 py-5 text-lg leading-6 transition-colors delay-0 ease-in-out focus:transition-colors focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
      },
      size: {
        default: "h-16 px-6 py-4 text-lg font-bold leading-6",
        sm: "py-2 px-3.5 text-sm font-medium leading-4",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
