import React from "react";
import clsx from "clsx";

export const Heading3: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
> = ({ className, children, ...props }) => {
  return (
    <h3 className={clsx("text-navy-800 text-base", className)} {...props}>
      {children}
    </h3>
  );
};
