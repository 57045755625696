import React from "react";
import clsx from "clsx";

const AuthLayoutHeader = React.forwardRef<
  React.ElementRef<"div">,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <div
      className={clsx(
        "flex max-w-lg flex-col items-center justify-center space-y-10",
        className
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </div>
  );
});

AuthLayoutHeader.displayName = "AuthLayoutHeader";

export default AuthLayoutHeader;
