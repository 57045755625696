import React from "react";
import clsx from "clsx";

export const Heading2: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
> = ({ className, children, ...props }) => {
  return (
    <h2 className={clsx("text-navy-800 text-4xl", className)} {...props}>
      {children}
    </h2>
  );
};
