import React, { type ForwardedRef } from "react";
import clsx from "clsx";

interface AuthLayoutFormBaseProps {
  container?: boolean;
  containerWidth?: "lg" | "xl";
  space?: boolean;
}

interface AuthLayoutFormFormProps
  extends AuthLayoutFormBaseProps,
    React.HTMLProps<HTMLFormElement> {}

interface AuthLayoutFormDivProps
  extends AuthLayoutFormBaseProps,
    React.HTMLProps<HTMLDivElement> {}

type AuthLayoutFormProps = AuthLayoutFormFormProps | AuthLayoutFormDivProps;

const baseClassName = "flex w-full flex-col items-center";
const nonContainerClassName =
  "border-navy-100 rounded-lg border border-solid bg-white p-6 shadow-md shadow-gray-100";
const finalClassName = (
  className: string | undefined,
  {
    container,
    space,
    containerWidth: width,
  }: Omit<AuthLayoutFormProps, "className" | "children">
) =>
  clsx(
    baseClassName,
    {
      [nonContainerClassName]: !container,
      "space-y-6": space,
      "max-w-xl md:max-w-[50vw]": width === "xl",
      "max-w-lg": width !== "xl",
    },
    className
  );

export const AuthLayoutContainerForm = React.forwardRef<
  HTMLFormElement,
  AuthLayoutFormProps
>(
  (
    {
      className,
      space,
      children,
      containerWidth,
      ...props
    }: AuthLayoutFormFormProps,
    forwardedRef: ForwardedRef<HTMLFormElement>
  ) => {
    return (
      <form
        className={finalClassName(className, {
          ...props,
          containerWidth,
          space,
        })}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </form>
    );
  }
);
AuthLayoutContainerForm.displayName = "AuthLayoutForm";

export const AuthLayoutContainer = React.forwardRef<
  HTMLDivElement,
  AuthLayoutFormDivProps
>(
  (
    {
      className,
      children,
      space,
      containerWidth,
      ...props
    }: AuthLayoutFormDivProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        className={finalClassName(className, {
          ...props,
          containerWidth,
          space,
        })}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </div>
    );
  }
);
AuthLayoutContainer.displayName = "AuthLayoutContainer";
